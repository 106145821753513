import React, { useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(2),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  cardContent: {
    flex: '1 0 auto',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function Docs({ token }) {
  const classes = useStyles();
  const [epgId, setEpgId] = useState('');
  const [epgName, setEpgName] = useState('');
  const [time, setTime] = useState('');
  const [maxResults, setMaxResults] = useState('');
  const [responseText, setResponseText] = useState('');

  const handleButtonClick = async () => {
    try {
      if (token) {
        try {
          const response = await fetch(
            `https://api.epg-api.com/v1.0/epg/getEpg?epgId=${epgId}&epgName=${epgName}&time=${time}&maxResults=${maxResults}`,
            {
              method: 'GET',
              headers: {
                Authorization: token,
                'Content-Type': 'application/json',
              },
            },
          );

          if (response.ok) {
            const data = await response.text();
            const formattedJson = JSON.stringify(JSON.parse(data), null, 2);

            setResponseText(formattedJson);
          } else if (response.status === 403) {
            const errorJson = await response.json();
            setResponseText(`Error: ${errorJson.message}`);
          } else {
            setResponseText(null);
          }
        } catch (error) {
          setResponseText(null);
        }
      } else {
        setResponseText(null);
      }
    } catch (error) {
      console.error('Error fetching EPG:', error);
      setResponseText('Error fetching EPG.');
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography variant="h4" align="center" gutterBottom>
        Docs
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card className={classes.card} sx={{ padding: '8px 8px 16px 8px' }}>
            <CardContent className={classes.cardContent}>
              <Box
                sx={{
                  backgroundColor: 'green',
                  color: 'white',
                  padding: '8px',
                  borderRadius: '4px',
                  display: 'inline',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                GET
              </Box>
              <Typography variant="h5" align="center">
                1. Step
              </Typography>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ marginTop: '16px' }}
              >
                /v1.0/auth/login
              </Typography>
              <Typography variant="subtitle2" align="center">
                Get EPG by EPG ID or EPG Name
              </Typography>
              <Typography
                variant="h5"
                align="center"
                style={{ marginTop: '16px' }}
              >
                Media Type
              </Typography>
              <Typography variant="subtitle1" align="center">
                application/json
              </Typography>
              <Typography
                variant="h5"
                align="center"
                style={{ marginTop: '16px' }}
              >
                Parameters
              </Typography>
              <Typography variant="subtitle1" align="center">
                username (string) - your username
              </Typography>
              <Typography variant="subtitle1" align="center">
                password (string) - your password
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card} sx={{ padding: '8px 8px 16px 8px' }}>
            <CardContent className={classes.cardContent}>
              <Box
                sx={{
                  backgroundColor: 'green',
                  color: 'white',
                  padding: '8px',
                  borderRadius: '4px',
                  display: 'inline',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                GET
              </Box>
              <Typography variant="h5" align="center">
                /v1.0/epg/getEpg
              </Typography>
              <Typography variant="subtitle1" align="center">
                Get EPG by EPG ID or EPG Name
              </Typography>
              <Typography
                variant="h8"
                align="center"
                style={{ marginTop: '16px', marginBottom: '8px' }}
              >
                Use the token from the login response as the Authorization
              </Typography>

              <TextField
                fullWidth
                label="EPG ID (string) - channelName.DE"
                value={epgId}
                onChange={(e) => setEpgId(e.target.value)}
                className={classes.textField}
                style={{ marginBottom: '14px' }}
              />
              <TextField
                fullWidth
                label="EPG Name (string) - Channel Name"
                value={epgName}
                onChange={(e) => setEpgName(e.target.value)}
                className={classes.textField}
                style={{ marginBottom: '14px' }}
              />
              <TextField
                fullWidth
                label="Time (string) - 20230417063500 - YYYYMMDDHHMMSS"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                className={classes.textField}
                style={{ marginBottom: '14px' }}
              />
              <TextField
                fullWidth
                label="Max Result (number) 1-10"
                value={maxResults}
                onChange={(e) => setMaxResults(e.target.value)}
                className={classes.textField}
              />
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button
                size="large"
                color="primary"
                fullWidth
                variant="contained"
                onClick={handleButtonClick}
              >
                Execute
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card} sx={{ padding: '8px 8px 16px 8px' }}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h5" align="center">
                Response
              </Typography>
              <Typography
                variant="h5"
                align="center"
                style={{ marginTop: '16px' }}
              >
                Response Headers (Authorization)
              </Typography>
              <Typography variant="subtitle1" align="center">
                token (string) - your token - expires in 24 hours
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card} sx={{ padding: '8px 8px 16px 8px' }}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h5" align="center">
                Response
              </Typography>
              <Typography variant="subtitle1" align="center">
                {responseText ? (
                  responseText.startsWith('Error: ') ? (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle2" color="text.secondary">
                        {responseText}
                      </Typography>
                    </Box>
                  ) : (
                    <pre style={{ whiteSpace: 'pre-wrap', overflowX: 'auto' }}>
                      {responseText}
                    </pre>
                  )
                ) : (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" color="text.secondary">
                      No response yet.
                    </Typography>
                  </Box>
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Docs;
