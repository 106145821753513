import React, { useState } from 'react';
import { Container, Box, TextField, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Login = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await fetch('https://api.epg-api.com/v1.0/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });
    if (response.status === 200 || response.status === 201) {
      const token = response.headers.get('Authorization');
      sessionStorage.setItem('token', token);
      navigate('/login');
      props.setToken(token);
      navigate('/dashboard');
    } else {
      setError('Failed to login user');
    }

    // const response2 = await fetch('http://localhost:3000/v1.0/auth/me', {
    //   method: 'GET',
    //   headers: {
    //     Authorization: response.headers.get('Authorization'),
    //     'Content-Type': 'application/json',
    //   },
    // });
  };

  return (
    // Add onSubmit handler to form
    <Container maxWidth="sm">
      <Box mt={4} mb={2}>
        <Typography variant="h4" align="center">
          Login
        </Typography>
      </Box>
      {error && (
        <Box mt={2} mb={2}>
          <Typography color="error" align="center">
            {error}
          </Typography>
        </Box>
      )}
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          label="Email"
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <Box mt={2} mb={2}>
          <Button variant="contained" color="primary" fullWidth type="submit">
            Login
          </Button>
        </Box>
        <Box mt={2}>
          <Typography variant="body2">
            Don't have an account? <a href="/register">Register</a>
            <Typography variant="body2">
              Forgot password? <a href="/forgot-password">Click here</a>
            </Typography>
          </Typography>
        </Box>
        <Box mt={2}></Box>
      </Box>
    </Container>
  );
};

export default Login;
