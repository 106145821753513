import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Container,
  Typography,
  Box,
  Grid,
  Paper,
  Button,
  Alert,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: '#f5f5f5',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const UpgradePlan = ({ user }) => {
  const classes = useStyles();

  if (!user) {
    return <div>Loading...</div>;
  }

  async function redirectToBilling() {
    // Ersetzen Sie diese URL durch die URL Ihres eigenen Backend-Servers
    const backendUrl =
      'https://api.epg-api.com/v1.0/stripe/create-portal-session';

    // Ersetzen Sie `sessionId` durch die Stripe Checkout Session-ID des Benutzers
    const customerId = user.stripeCustomerId;

    try {
      const response = await fetch(backendUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customer_id: customerId }),
      });

      if (response.ok) {
        const data = await response.json();
        // Umleiten des Benutzers zur Portal-URL
        window.location.href = data;
      } else {
        console.error('Failed to create portal session');
      }
    } catch (error) {
      console.error('Error creating portal session:', error);
    }
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography variant="h4" align="center" gutterBottom>
        Upgrade Your Plan
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12} marginTop={5}>
          <Paper className={classes.paper}>
            <button
              class="manage-subscription-btn"
              onClick={() => redirectToBilling()}
            >
              Upgrade My Subscription
            </button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UpgradePlan;
