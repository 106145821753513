import React from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  cardContent: {
    flex: '1 0 auto',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Pricing = ({ token, setToken }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleSelectPlan = (product) => {
    if (token) {
      navigate('/checkout', { state: { product: product } });
    } else {
      navigate('/login');
    }
  };

  const pricingData = [
    {
      id: 1,
      title: 'Basic',
      price: 29,
      features: [
        '100 API calls per month',
        'Email support',
        'Limited access to premium features',
      ],
      priceId: 'price_1N0rHHJA9tmViW5aNhDNmWlc',
    },
    {
      id: 2,
      title: 'Premium',
      price: 49,
      features: [
        '30.000 API calls per month',
        'Priority email support',
        'Full access to all premium features',
      ],
      priceId: 'price_1N0rHWJA9tmViW5ay107zbLp',
    },
    {
      id: 3,
      title: 'Enterprise',
      price: 99,
      features: [
        '100.000 API calls per month',
        '24/7 priority support',
        'Customizable features and integrations',
      ],
      priceId: 'price_1N0rHkJA9tmViW5aAbBsAI8M',
    },
  ];

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography variant="h4" align="center" gutterBottom>
        Pricing Plans
      </Typography>
      <Grid container spacing={2}>
        {pricingData.map((plan) => (
          <Grid item xs={12} md={4} key={plan.id}>
            <Card className={classes.card} sx={{ padding: '8px 8px 16px 8px' }}>
              <CardContent className={classes.cardContent}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="h5" align="center">
                    {plan.title}
                  </Typography>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="h3" align="center">
                    ${plan.price}
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      component="span"
                    >
                      /month
                    </Typography>
                  </Typography>
                </Box>
                <Typography variant="subtitle1" align="center">
                  {plan.features.map((feature) => (
                    <Box key={feature} sx={{ mb: 1 }}>
                      {feature}
                    </Box>
                  ))}
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button
                  size="large"
                  color="primary"
                  fullWidth
                  variant="contained"
                  onClick={() => handleSelectPlan(plan)}
                >
                  Select Plan
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Pricing;
