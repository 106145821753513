// src/components/ForgotPassword.js
import React, { useState } from 'react';
import { Container, Box, TextField, Button, Typography } from '@mui/material';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await fetch(
      'https://api.epg-api.com/v1.0/auth/forgot-password',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      },
    );

    if (response.ok) {
      alert('Password reset email has been sent.');
    } else {
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box mt={4} mb={2}>
        <Typography variant="h4" align="center">
          Forgot Password
        </Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          label="Email"
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <Box mt={2} mb={2}>
          <Button variant="contained" color="primary" fullWidth type="submit">
            Send Reset Email
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
