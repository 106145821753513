import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../assets/animation.json';
import './Home.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  const epgData = {
    channel: 'TRT 1',
    title: 'Sample Show',
    description: 'This is a sample show on TRT 1',
    startTime: '2023-04-07T19:00:00',
    endTime: '2023-04-07T20:00:00',
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleRegister = () => {
    navigate('/register');
  };

  return (
    <div className="container">
      <div className="text-content">
        <div className="com-text">
          <h1>Welcome to Our EPG API Service</h1>
          <p>
            Looking to improve your IPTV service with detailed program
            information and scheduling?
          </p>
          <p>
            Our API for EPG data provides reliable and accurate data that's easy
            to integrate into your service. Simply log in and choose a plan to
            get started today.
          </p>
          <p>
            Enhance your customer's TV experience with our EPG API and provide
            them with the information they need to fully enjoy their viewing
            experience. Don't wait, sign up now and take your IPTV service to
            the next level!
          </p>
        </div>
        <pre className="epg-example">
          {JSON.stringify(epgData, null, 2)
            .split('\n')
            .map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
        </pre>
        <Button variant="contained" color="warning" onClick={handleRegister}>
          Register Now
        </Button>
      </div>
      <div className="lottie-container">
        <Lottie options={defaultOptions} />
      </div>
    </div>
  );
};

export default Home;
