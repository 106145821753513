import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';
import BurgerMenu from './BurgerMenu';

const useStyles = makeStyles({
  toolbar: {
    justifyContent: 'space-between',
  },
});

const Navbar = ({ token, setToken }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleLogout = () => {
    setToken(null);
    sessionStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <AppBar position="static" elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Typography
          variant="h6"
          onClick={() => navigate('/')}
          className="navbar-logo"
        >
          EPG API
        </Typography>
        <div className="navbar-links">
          <Button color="inherit" onClick={() => navigate('/')}>
            Home
          </Button>
          <Button color="inherit" onClick={() => navigate('/pricing')}>
            Pricing
          </Button>
          <Button color="inherit" onClick={() => navigate('/docs')}>
            Docs
          </Button>
          {token ? (
            <div>
              <Button color="inherit" onClick={() => navigate('/dashboard')}>
                Dashboard
              </Button>
              <Button color="inherit" onClick={handleLogout}>
                Logout
              </Button>
            </div>
          ) : (
            <div>
              <Button color="inherit" onClick={() => navigate('/login')}>
                Login
              </Button>
              <Button color="inherit" onClick={() => navigate('/register')}>
                Register
              </Button>
            </div>
          )}
        </div>
        <BurgerMenu token={token} setToken={setToken} />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
