import React from 'react';
import { Container, Box, Typography } from '@mui/material';

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md">
      <Box mt={4} mb={2}>
        <Typography variant="h4" align="center">
          Terms and Conditions
        </Typography>
      </Box>
      <Typography variant="body1" paragraph>
        <Typography variant="h6">1. Scope</Typography>
        <p>
          These Terms and Conditions apply to all users of the [Your Website
          Name] EPG API service, provided by [Your Company Name], hereinafter
          referred to as the "Service".
        </p>

        <Typography variant="h6">2. Service Description</Typography>
        <p>
          The Service provides electronic program guide (EPG) data to
          subscribers. The data provided includes, but is not limited to,
          program titles, descriptions, start and end times, and channel
          information.
        </p>

        <Typography variant="h6">3. Registration and Account</Typography>
        <p>
          Users must register for an account to access the Service. Users are
          responsible for providing accurate and complete information during
          registration and maintaining the confidentiality of their account
          credentials.
        </p>

        <Typography variant="h6">4. Fees and Payment</Typography>
        <p>
          Access to the Service is granted upon payment of the applicable fees.
          The fees, payment terms, and any applicable taxes will be displayed
          during the registration process. Users are responsible for timely
          payment of all fees and charges associated with their use of the
          Service.
        </p>

        <Typography variant="h6">5. Data Usage and Restrictions</Typography>
        <p>
          Users are granted a non-exclusive, non-transferable, revocable license
          to access and use the EPG data provided by the Service for their own
          personal or commercial purposes, in accordance with these Terms and
          Conditions. Users may not resell, sublicense, or otherwise distribute
          the EPG data without the express written consent of [Your Company
          Name].
        </p>

        <Typography variant="h6">6. Termination</Typography>
        <p>
          [Your Company Name] reserves the right to terminate a user's access to
          the Service for any reason, including but not limited to, violation of
          these Terms and Conditions, non-payment of fees, or abuse of the
          Service.
        </p>

        <Typography variant="h6">7. Warranty and Liability</Typography>
        <p>
          The Service is provided "as is" without warranty of any kind. [Your
          Company Name] does not guarantee the accuracy, completeness, or
          timeliness of the EPG data provided. In no event shall [Your Company
          Name] be liable for any damages, including but not limited to, direct,
          indirect, incidental, or consequential damages, arising out of the use
          or inability to use the Service.
        </p>

        <Typography variant="h6">8. Changes to Terms and Conditions</Typography>
        <p>
          [Your Company Name] reserves the right to modify these Terms and
          Conditions at any time. Users are responsible for regularly reviewing
          these Terms and Conditions to stay informed about any changes.
        </p>

        <Typography variant="h6">9. Governing Law</Typography>
        <p>
          These Terms and Conditions shall be governed by and construed in
          accordance with the laws of Germany. Any disputes arising out of or in
          connection with the use of the Service shall be subject to the
          jurisdiction of the competent courts in Germany.
        </p>
      </Typography>
      {/* Add more content related to your Legal Terms and Conditions here */}
    </Container>
  );
};

export default TermsAndConditions;
