import React, { useState } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Register = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [checked, setChecked] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!checked) {
      setError('Please accept the Terms and Conditions and Privacy Policy.');
      return;
    }

    try {
      // Convert the data to URL-encoded format
      const data = new URLSearchParams();
      data.append('name', name);
      data.append('email', email);
      data.append('password', password);
      const encodedData = data.toString();

      // Make the HTTP POST request with URL-encoded data
      const response = await axios.post(
        'https://api.epg-api.com/v1.0/auth/register',
        encodedData,
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        },
      );
      if (response.status === 200 || response.status === 201) {
        setSuccess('User registered successfully');
        navigate('/login');
      } else {
        setError('Failed to register user');
      }
    } catch (error) {
      console.error(error);
      setError('Failed to register user. Please contact support.');
    }
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Container maxWidth="sm">
      <Box mt={4} mb={2}>
        <Typography variant="h4" align="center">
          Register
        </Typography>
        {error && (
          <Box mt={2} mb={2}>
            <Typography color="error" align="center">
              {error}
            </Typography>
          </Box>
        )}
        {success && (
          <Box mt={2} mb={2}>
            <Typography color="success" align="center">
              Registration successful!
            </Typography>
          </Box>
        )}
      </Box>
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          label="Name"
          type="text"
          variant="outlined"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          label="Email"
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={checked}
              onChange={handleCheckboxChange}
            />
          }
          label={
            <>
              I accept the{' '}
              <a href="/terms-and-conditions">Terms and Conditions</a> and{' '}
              <a href="/privacy-policy">Privacy Policy</a>.
            </>
          }
        />
        <Box mt={2} mb={2}>
          <Button variant="contained" color="primary" fullWidth type="submit">
            Register
          </Button>
        </Box>
        <Box mt={2}>
          <Typography variant="body2" align="center">
            Already have an account? <a href="/login">Login</a>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Register;
