import React from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';
import './Dashboard.css';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
  },
  title: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  featureItem: {
    margin: theme.spacing(1),
  },
}));

const Dashboard = ({ user }) => {
  const classes = useStyles();

  if (!user) {
    return <div>Loading...</div>;
  }

  async function redirectToBilling() {
    // Ersetzen Sie diese URL durch die URL Ihres eigenen Backend-Servers
    const backendUrl =
      'https://api.epg-api.com/v1.0/stripe/create-portal-session';

    // Ersetzen Sie `sessionId` durch die Stripe Checkout Session-ID des Benutzers
    const customerId = user.stripeCustomerId;

    try {
      const response = await fetch(backendUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customer_id: customerId }),
      });

      if (response.ok) {
        const data = await response.json();
        // Umleiten des Benutzers zur Portal-URL
        window.location.href = data;
      } else {
        console.error('Failed to create portal session');
      }
    } catch (error) {
      console.error('Error creating portal session:', error);
    }
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography variant="h4" align="center" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper} style={{}}>
            <div class="account-information">
              <h2 class="account-title">Account Information</h2>
              <ul class="account-details">
                <li class="account-detail">
                  <span class="account-label">Email:</span>
                  <span class="account-value">{user.email}</span>
                </li>
                <li class="account-detail">
                  <span class="account-label">Current Plan:</span>
                  <span class="account-value">{user.package}</span>
                </li>
                <li class="account-detail">
                  <span class="account-label">API Calls This Month:</span>
                  <span class="account-value">{user.callsPerMonth}</span>
                </li>
                <li class="account-detail">
                  <span class="account-label">API Calls Reset:</span>
                  <span class="account-value">{user.lastResetDate}</span>
                </li>
              </ul>
            </div>
          </Paper>
          <Paper className={classes.paper} style={{ marginTop: '10px' }}>
            <div class="subscription-information">
              <h2 class="subscription-title">Subscription Information</h2>
              <ul class="subscription-details">
                <li class="subscription-detail">
                  <span class="subscription-label">Start Date:</span>
                  <span class="subscription-value">
                    {user.subscriptionStart}
                  </span>
                </li>
                <li class="subscription-detail">
                  <span class="subscription-label">
                    {user.isCanceled ? 'Subscription End:' : 'Next Payment:'}
                  </span>
                  <span class="subscription-value">{user.subscriptionEnd}</span>
                </li>
                {user.subscriptionEnd !== null && user.isCanceled && (
                  <li class="subscription-detail">
                    <span class="subscription-label">Status:</span>
                    <span class="subscription-value cancelled">
                      Subscription Canceled
                    </span>
                  </li>
                )}
                <li class="subscription-detail">
                  <button
                    class="manage-subscription-btn"
                    onClick={() => redirectToBilling()}
                  >
                    Manage My Subscription
                  </button>
                </li>
              </ul>
            </div>
          </Paper>
          <Paper className={classes.paper} style={{ marginTop: '10px' }}>
            <div class="support-contact">
              <h2 class="support-title">Support & Contact</h2>
              <ul class="support-details">
                <li class="support-detail">
                  <span class="support-label">Email:</span>
                  <span class="support-value">info@epg-api.com</span>
                </li>
              </ul>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8}>
          <Paper className={classes.paper} style={{ minHeight: '180px' }}>
            <div class="news-article">
              <div class="news-header">
                <h2 class="news-title">Introducing our EPG API</h2>
                <p class="news-meta">By Admin | April 18, 2023 | Welcome</p>
              </div>
              <div class="news-body">
                <p>
                  We are delighted to introduce the first version of our EPG API
                  to you! This release marks a significant milestone in our
                  journey to provide a powerful and reliable Electronic Program
                  Guide solution tailored to your needs.
                </p>
                <p>
                  Our EPG API offers a comprehensive and up-to-date collection
                  of television program information, allowing you to create a
                  seamless and engaging user experience for your audience. We
                  have carefully designed and developed this API with
                  performance, accuracy, and ease-of-use in mind.
                </p>
                <p>
                  We are committed to continuously improving and expanding our
                  EPG API based on your feedback and the ever-evolving needs of
                  the industry. Your support and satisfaction are our top
                  priorities, and we are excited to see the incredible
                  applications you will build using our EPG API.
                </p>
                <p>
                  Thank you for choosing our service, and we look forward to
                  working together to revolutionize the way users discover and
                  enjoy their favorite TV content.
                </p>
              </div>
            </div>
          </Paper>
          <Paper className={classes.paper} style={{ marginTop: '10px' }}>
            <div class="patch-notes">
              <h2 class="patch-title">EPG API v1.0 Release Notes</h2>
              <div class="patch-version">
                <h3 class="patch-version-title">v1.0</h3>
                <ul class="patch-version-changes">
                  <li>
                    Introducing EPG API v1.0 - our first release, marking a
                    milestone in delivering a reliable and robust service.
                  </li>
                </ul>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
