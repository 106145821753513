import React, { useState } from 'react';
import { MdMenu, MdClose } from 'react-icons/md';
import './BurgerMenu.css';
import { useNavigate } from 'react-router-dom';

const BurgerMenu = ({ token, setToken }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    const body = document.querySelector('body');
    body.classList.toggle('noscroll');
  };

  const handleLogout = () => {
    try {
      setToken(null);
      sessionStorage.removeItem('token');
      navigate('/login');
    } catch (error) {
      alert(error);
    }
  };

  const renderNavLinks = () => {
    if (token) {
      // If user is logged in
      return (
        <>
          <a href="/" onClick={toggleMenu}>
            Home
          </a>
          <a href="/pricing" onClick={toggleMenu}>
            Pricing
          </a>
          <a href="/docs" onClick={toggleMenu}>
            Docs
          </a>
          {token ? (
            <>
              <a href="/dashboard">Dashboard</a>
              <button
                onClick={() => {
                  toggleMenu();
                  handleLogout();
                }}
                style={{
                  background: 'none',
                  border: 'none',
                  color: 'white',
                  cursor: 'pointer',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  outline: 'none',
                  padding: '0',
                  textDecoration: 'none',
                }}
              >
                Logout
              </button>
            </>
          ) : (
            <>
              <a href="/login">Login</a>
              <a href="/register">Register</a>
            </>
          )}
        </>
      );
    } else {
      // If user is not logged in
      return (
        <>
          <a href="/home" onClick={toggleMenu}>
            Home
          </a>
          <a href="/pricing" onClick={toggleMenu}>
            Pricing
          </a>
          <a href="/login" onClick={toggleMenu}>
            Login
          </a>
          <a href="/register" onClick={toggleMenu}>
            Register
          </a>
        </>
      );
    }
  };

  return (
    <div className="burger-menu">
      <div onClick={toggleMenu}>
        <MdMenu size={30} />
      </div>
      {menuOpen && (
        <div className="menu">
          <div className="menu-close" onClick={toggleMenu}>
            <MdClose size={30} />
          </div>
          {renderNavLinks()}
        </div>
      )}
    </div>
  );
};

export default BurgerMenu;
