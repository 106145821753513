import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './Layout.css';

const Layout = ({ children, token, setToken }) => {
  return (
    <div className="layout">
      <Navbar token={token} setToken={setToken} />
      <div className="content">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
