import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  cardContent: {
    flex: '1 0 auto',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ProductDisplay = ({ product, email }) => {
  const classes = useStyles();
  return (
    <Container maxWidth="sm" className={classes.container}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h5" align="center" gutterBottom>
            {product?.title}
          </Typography>
          <Typography variant="h3" align="center" gutterBottom>
            {product?.price}
            <Typography
              variant="subtitle2"
              color="text.secondary"
              component="span"
            >
              /month
            </Typography>
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <form
            action="https://api.epg-api.com/v1.0/stripe/create-checkout-session"
            method="POST"
          >
            <input type="hidden" name="priceId" value={product?.priceId} />
            <input type="hidden" name="email" value={email} />
            <Button
              id="checkout-and-portal-button"
              type="submit"
              size="large"
              color="primary"
              fullWidth
              variant="contained"
            >
              Checkout
            </Button>
          </form>
        </CardActions>
      </Card>
    </Container>
  );
};
const SuccessDisplay = ({ sessionId }) => {
  return (
    <CenteredContainer>
      <section className="product">
        <div className="product Box-root">
          <div className="description Box-root">
            <h3>Subscription to starter plan successful!</h3>
          </div>
        </div>
        <form action="/create-portal-session" method="POST">
          <input
            type="hidden"
            id="session-id"
            name="session_id"
            value={sessionId}
          />
          <button id="checkout-and-portal-button" type="submit">
            Manage your billing information
          </button>
        </form>
      </section>
    </CenteredContainer>
  );
};

const Message = ({ message }) => (
  <CenteredContainer>
    <section>
      <p>{message}</p>
    </section>
  </CenteredContainer>
);

const CenteredContainer = ({ children }) => (
  <div className="centered-container">{children}</div>
);

const Checkout = ({ user }) => {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');
  const location = useLocation();
  const [product, setProduct] = useState(location?.state?.product);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready.",
      );
    }
  }, [sessionId]);

  if (!success && message === '') {
    return <ProductDisplay product={product} email={user.email} />;
  } else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
};

export default Checkout;
