import React, { useEffect, useState } from 'react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Pricing from './pages/Pricing';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Legal from './pages/Legal';
import TermsAndConditions from './pages/TermsAndConditions';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import animationData from './assets/animation2.json';
import Lottie from 'react-lottie';
import Checkout from './pages/Checkout';
import Docs from './pages/Docs';
import UpgradePlan from './pages/UpgradePlan';
import ForgotPassword from './pages/ForgotPassword';
import ResetPasswordForm from './pages/ResetPassword';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

function App() {
  const [token, setToken] = useState(sessionStorage.getItem('token'));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="lottie-container-bg">
        <Lottie options={defaultOptions} />
      </div>
      <Router>
        <AppContent token={token} setToken={setToken} />
      </Router>
    </ThemeProvider>
  );
}

function AppContent({ token, setToken }) {
  const [user, setUser] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchUser = async () => {
      if (token) {
        try {
          const response = await fetch('https://api.epg-api.com/v1.0/auth/me', {
            method: 'GET',
            headers: {
              Authorization: token,
              'Content-Type': 'application/json',
            },
          });

          if (response.ok) {
            const data = await response.json();
            setUser(data);
          } else {
            setUser(null);
          }
        } catch (error) {
          setUser(null);
        }
      } else {
        setUser(null);
      }
    };

    fetchUser();
  }, [token, location]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout token={token} setToken={setToken}>
            <Home />
          </Layout>
        }
      />
      <Route
        path="/login"
        element={
          <Layout token={token} setToken={setToken}>
            <Login setToken={setToken} />
          </Layout>
        }
      />
      <Route
        path="/register"
        element={
          <Layout token={token} setToken={setToken}>
            <Register />
          </Layout>
        }
      />
      <Route
        path="/pricing"
        element={
          <Layout token={token} setToken={setToken}>
            {user ? (
              user.package === 'Free' ? (
                <Pricing token={token} setToken={setToken} />
              ) : (
                <UpgradePlan user={user} />
              )
            ) : (
              <Login setToken={setToken} />
            )}
          </Layout>
        }
      />
      <Route
        path="/checkout"
        element={
          <Layout token={token} setToken={setToken}>
            {user ? (
              user.package === 'Free' ? (
                <Checkout user={user} token={token} setToken={setToken} />
              ) : (
                <Dashboard user={user} />
              )
            ) : (
              <Login setToken={setToken} />
            )}
          </Layout>
        }
      />
      <Route
        path="/dashboard"
        element={
          <Layout token={token} setToken={setToken}>
            {user ? <Dashboard user={user} /> : <Login setToken={setToken} />}
          </Layout>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <Layout token={token} setToken={setToken}>
            <PrivacyPolicy />
          </Layout>
        }
      />
      <Route
        path="/terms-and-conditions"
        element={
          <Layout token={token} setToken={setToken}>
            <TermsAndConditions />
          </Layout>
        }
      />
      <Route
        path="/legal"
        element={
          <Layout token={token} setToken={setToken}>
            <Legal />
          </Layout>
        }
      />
      <Route
        path="/docs"
        element={
          <Layout token={token} setToken={setToken}>
            {user ? (
              <Docs token={token} setToken={setToken} />
            ) : (
              <Login setToken={setToken} />
            )}
          </Layout>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <Layout>
            <ForgotPassword />
          </Layout>
        }
      />
      <Route
        path="/reset-password"
        element={
          <Layout>
            <ResetPasswordForm />
          </Layout>
        }
      />
    </Routes>
  );
}

export default App;
